import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Nasb`}</strong>{` or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`نسب`}</strong>{` in Arabic helps us to attribute/associate a person to particular country, city etc. Suppose we want to say this person is from Misr, we will say  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`هذا مصري`}</code></p>
    <br />
    <p>{`Where word `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`مصري`}</code>{` is made from word `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`مصر`}</code>{` by adding `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يّ`}</code>{` known as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ياء النسب`}</code>{` in the end i.e.  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`مِصْرِ + يّ`}</code>{` = `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`مصري`}</code>{`    `}</p>
    <br />
    <p>{`Sometimes, Arab makes Nasb in فَعّال form. We mostly use this form in the names of people involved in a handicraft. For example,`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`حَدَّاد`}</code>{` (blacksmith) from `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حديد`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`نجَّار`}</code>{` (Carpentar)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`بَقَّال`}</code>{` (Owner of Grocary)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`حذّاء`}</code>{` (shoemaker)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.576687116564415%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5klEQVQ4y42TW2/aQBCF/f+f+1D1pVJfqqqpGqmNqIIEqJCQmqRAoC6BUC65CIKNjY2vYH9dG4MAkaYjHc36zMzRzOxaYsuiKFp5gTBFtI5tYT9/26T9YOg8sRzXwPgN5g3oCuZABu2XQJNIbRKM6gTe/KDojmDSmd4hGhQZXBzTKX7k/PgNuQ+vMJQsV5m3tAUX9gvM1IeXBWNbWo8Yt2dU859ofP8s/BHdSoZB9ZRq7oib8lemnRKWof6fYCi869qEy4AoDDB0DVWdoKmq+F6wXPg4jpPk/VMwje4k6PoMzw+Ss+t6GIZ54EL2BKNUJEYYRhthTZvief6q6zBMvOO4gtc3xTG/XR9D2r92P/BRpxrLcLn7lNY7FvxEjB8sAg6Z9DScMrkzBHSM0ZxOo0/7uof2MMNPx12LuXMv4Vu1bpKn3uuMB1qCiTjHHUuDxojCyTn5LyXK2UvkXI1qsUlL7qNrVjraSlAfm9z+HDJqTylmLnj3+j2VfJ07ZYwi/xEr8ZF6zUdK2QrVM4UfhRqFb2WacgflssfMSB9vKmjpNu3akE79DrlQp3gqUy+36F7fo1z1xM4DJHvuJsqO7Qn4eO6CmLMsZ9PZ9h7ngrdMG9dZ53qYMxtb1Me/hsQLFj3z3p6zvzYH4vfjIHvKAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Nasb forms",
          "title": "Nasb forms",
          "src": "/static/fff11ba9ca9bfaf90f7fd881c7272436/13ae7/nasb.png",
          "srcSet": ["/static/fff11ba9ca9bfaf90f7fd881c7272436/222b7/nasb.png 163w", "/static/fff11ba9ca9bfaf90f7fd881c7272436/ff46a/nasb.png 325w", "/static/fff11ba9ca9bfaf90f7fd881c7272436/13ae7/nasb.png 350w"],
          "sizes": "(max-width: 350px) 100vw, 350px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Categories of Nasb`}</h2>
    <p>{`Nasb forms can only be made with Nouns (اسم) and is divided into following categories,`}</p>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 1`}</strong>{` `}<strong parentName="p">{`Three letter nouns having ة مربوطة at end`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Nasb:`}</strong>{` Remove the ة and add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يّ`}</code><br parentName="p"></br>{`
`}{`For example, we have`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`مكة`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مكّ`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مَكِّيّ`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`شجرة`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`شجر`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`شجرِيّ`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`حنيفة`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حنيف`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`حنفِيّ`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`بصرة`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`بصر`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`بصريّ`}</code></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`إذا كان في آخر الاسم المنسوب تاء تأنيث يجب أن نحذفها`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 2`}</strong>{` `}<strong parentName="p">{`Nouns having الاسم المقصور at end`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 2a`}</strong>{` `}<strong parentName="p">{`Three letter nouns having ا as third letter (إذا كان الألف الحرف الثالث)`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Nasb:`}</strong>{` Turn ا into و and add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يّ`}</code><br parentName="p"></br>{`
`}{`For example, we have`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`فتى`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`فتو`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`فتوِيّ`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`ربا`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ربو`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`رِبَوِيّ`}</code></li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 2b`}</strong>{` `}<strong parentName="p">{`Four letter nouns having ا as fourth letter (إذا كان الألف الحرف الرابع)`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`If second letter has harakah (الحرف الثاني متحرك)`}<br parentName="li"></br>
        <strong parentName="li" {...{
          "className": "heading2"
        }}>{`Transformation Rule of Nasb:`}</strong>{` Drop ا and add `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يّ`}</code><br parentName="li"></br>
        {`For example, we have`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`كنَدا`}</code>{` => `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`كَنَد`}</code>{` => `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`كندِيّ`}</code></li>
        </ul>
      </li>
    </ul>
    <br />
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If second letter has Sukun (الحرف الثاني ساكن)  `}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p" {...{
                "className": "heading2"
              }}>{`Transformation Rule of Nasb (Option 1):`}</strong>{` Drop ا and add `}<code parentName="p" {...{
                "className": "language-text"
              }}>{`يّ`}</code><br parentName="p"></br>{`
`}{`For example, we have`}</p>
            <div parentName="li" {...{
              "className": "gatsby-highlight",
              "data-language": "text"
            }}><pre parentName="div" {...{
                "className": "language-text"
              }}><code parentName="pre" {...{
                  "className": "language-text"
                }}>{`- \`\`\`حبلى\`\`\` => \`\`\`حبل\`\`\` => \`\`\`حُبْلِيّ\`\`\`
- \`\`\`دنيا\`\`\` => \`\`\`دني\`\`\` => \`\`\`دنييّ\`\`\`  `}</code></pre></div>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p" {...{
                "className": "heading2"
              }}>{`Transformation Rule of Nasb (Option 2):`}</strong>{` Turn ا into و and add `}<code parentName="p" {...{
                "className": "language-text"
              }}>{`يّ`}</code><br parentName="p"></br>{`
`}{`For example, we have`}</p>
            <div parentName="li" {...{
              "className": "gatsby-highlight",
              "data-language": "text"
            }}><pre parentName="div" {...{
                "className": "language-text"
              }}><code parentName="pre" {...{
                  "className": "language-text"
                }}>{`- \`\`\`حبلى\`\`\` => \`\`\`حبلو\`\`\` => \`\`\`حُبْلَوِيّ\`\`\`
- \`\`\`دنيا\`\`\` => \`\`\`دنيو\`\`\` => \`\`\`دنيويّ\`\`\``}</code></pre></div>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Category 2c`}</strong>{` `}<strong parentName="p">{`Five letter nouns having ا as fifth letter (إذا كان الألف الحرف الخامس)`}</strong><br parentName="p"></br>{`
`}<strong parentName="p" {...{
        "className": "heading2"
      }}>{`Transformation Rule of Nasb:`}</strong>{` Drop ا and add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يّ`}</code><br parentName="p"></br>{`
`}{`For example, we have`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`مصطفى`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مصطف`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`مصطفِيّ`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`فرنسا`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`فرنس`}</code>{` => `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`فرنسيّ`}</code><br parentName="li"></br>
        <strong parentName="li">{`Note`}</strong>{`: Same rule can be applied with words having ا as sixth letter or more than that`}</li>
    </ul>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      